
import { computed, defineComponent, onMounted, ref, watch } from 'vue'

import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import SessionStorage from '@/core/services/SessionStorage'
import { getTemplateImagesBySection } from '@/core/helpers/template/template'
import { useI18n } from 'vue-i18n'
import { useMobileDetection } from 'vue3-mobile-detection'

export default defineComponent({
  name: 'LoginTryItNow',
  setup () {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    const leadUuid = router.currentRoute.value.params.id
    const submitButton = ref<HTMLButtonElement | null>(null)
    const lead = ref({}) as any
    const formStep = ref(1) as any
    const selectedModuleType = ref(-1) as any
    const selectedModuleLength = ref(-1) as any
    const email = ref('')
    const name = ref('')
    const phone = ref('')
    const dialogMessage = ref(t('tryItOut.dialog.module'))
    const dialogMessageDangerState = ref(false)
    const mobileViewMode = computed(() => store.getters.getMobileViewMode)

    const styleObj = computed(() => {
      const calcByStep = 500 * (formStep.value - 1)
      const style = {} as any
      style.transform = `${'translateX(-' + calcByStep + 'px) !important'}`
      return style
    })

    const onNextClicked = () => {
      if (formStep.value >= 5) return // guard code in case next is ever visible on last step
      if (formStep.value === 4) { // validate email
        if (email.value.length === 0) {
          dialogMessageDangerState.value = true
          dialogMessage.value = 'Email is required'
          return
        }
        if (!isValidEmail(email.value)) {
          // dialogMessage.value = 'Email is invalid'
          return
        }
        store.dispatch(Actions.API_UPDATE_LEAD, { leadUuid: leadUuid, email: email.value })
      }
      if (formStep.value === 2) { // validate name
        if (name.value.length === 0) {
          dialogMessageDangerState.value = true
          dialogMessage.value = 'Name is required'
          return
        }
        if (name.value.length < 2) {
          dialogMessageDangerState.value = true
          dialogMessage.value = 'Name must be at least 2 letters long'
          return
        }
        store.dispatch(Actions.API_UPDATE_LEAD, { leadUuid: leadUuid, name: name.value })
      }
      formStep.value = formStep.value + 1
    }

    const onTestAGameClicked = () => {
      if (formStep.value === 5) { // validate phone number
        if (phone.value.length === 0) {
          dialogMessageDangerState.value = true
          dialogMessage.value = 'Phone is required'
          return
        }
        if (!validatePhoneNumber(phone.value)) {
          dialogMessageDangerState.value = true
          dialogMessage.value = 'Phone is invalid'
          return
        }

        dialogMessageDangerState.value = false

        submitButton.value!.disabled = true
        submitButton.value!.setAttribute('data-kt-indicator', 'on')

        store.dispatch(Actions.API_UPDATE_LEAD, {
          leadUuid: leadUuid,
          phone: phone.value,
          createPublicLink: true
        }).then((response) => { // once phone is stored we proceed to log in the user
          const values = {} as any
          values.publicSessionUuid = response.payload.publicSessionUuid
          values.name = response.payload.name
          // navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
          executeLogin(values)
          // }).catch((err) => {
          //   // // Deactivate indicator
          //   submitButton.value?.removeAttribute('data-kt-indicator')
          //   //   // eslint-disable-next-line
          //   submitButton.value!.disabled = false
          //   Swal.fire({
          //     html: err,
          //     icon: 'error',
          //     buttonsStyling: false,
          //     confirmButtonText: 'Okay',
          //     customClass: {
          //       confirmButton: 'btn fw-bold btn-light-danger'
          //     }
          //   })
          // })
        })
      }
    }

    const executeLogin = (values) => {
      store.dispatch(Actions.API_ACTIVE_USER_PUBLIC_LOGIN, values).then((response) => {
        values.accessCode = response.payload.activeUser.accessCode
        values.uid = response.payload.activeUser.activeUserUuid
        store.dispatch(Actions.API_ACTIVE_USER_LOGIN, values).then((response) => {
          const imagesForPreload = getTemplateImagesBySection(response.payload.template.sections[0], response.payload.activeUser.company)
          const tempImg = [] as any
          for (let x = 0; x < imagesForPreload.length; x++) {
            tempImg[x] = new Image()
            tempImg[x].src = imagesForPreload[x]
          }
          SessionStorage.deleteItem('clickedSection-template-' + response.payload.template.templateUuid)
          // push to router
          router.push({ name: 'curiosity-session' })
          if (!SessionStorage.getItem('loggedInAt')) {
            SessionStorage.saveItem('loggedInAt', new Date())
          }
        }).catch((response) => {
          console.log('could not log in', response)
          // // Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator')
          //   // eslint-disable-next-line
          submitButton.value!.disabled = false

          Swal.fire({
            html: response.data.error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
      }).catch((response) => {
        console.log('could not log in', response)
        submitButton.value?.removeAttribute('data-kt-indicator')
        //   // eslint-disable-next-line
        submitButton.value!.disabled = false
        Swal.fire({
          html: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    }

    const onModuleTypeClicked = (moduleType) => {
      if (formStep.value !== 1) return
      selectedModuleType.value = moduleType
      store.dispatch(Actions.API_UPDATE_LEAD, { leadUuid: leadUuid, gameCategory: selectedModuleType.value })
      setTimeout(() => {
        formStep.value = 2
      }, 300)
    }

    const onModuleLengthClicked = (moduleLength) => {
      if (formStep.value !== 3) return
      selectedModuleLength.value = moduleLength
      store.dispatch(Actions.API_UPDATE_LEAD, { leadUuid: leadUuid, gameLength: selectedModuleLength.value })
      setTimeout(() => {
        formStep.value = 4
      }, 300)
    }

    const onPreviousClicked = (e) => {
      e.preventDefault()
      if (formStep.value === 1) return
      formStep.value = formStep.value - 1
    }

    watch(formStep, (nv, ov) => {
      dialogMessageDangerState.value = false
      if (formStep.value === 1) {
        dialogMessage.value = t('tryItOut.dialog.module')
      }
      if (formStep.value === 2) {
        dialogMessage.value = t('tryItOut.dialog.name')
      }
      if (formStep.value === 3) {
        dialogMessage.value = t('tryItOut.dialog.moduleLength')
      }
      if (formStep.value === 4) {
        dialogMessage.value = t('tryItOut.dialog.email')
      }
      if (formStep.value === 5) {
        dialogMessage.value = t('tryItOut.dialog.phone')
      }
    })

    const isLoginFading = ref(false)
    onBeforeRouteLeave((to, from, next) => {
      isLoginFading.value = true
      setTimeout(() => {
        next()
      }, 100)
    })

    onMounted(() => {
      const leadUuid = router.currentRoute.value.params.id
      store.dispatch(Actions.API_GET_LEAD_BY_UUID, leadUuid).then((response) => {
        if (response.error && response.error.code === 'lead.could.not.be.found') {
          console.log('Lead not found. Going to create...')
          store.dispatch(Actions.API_CREATE_LEAD, { leadUuid: leadUuid })
          return
        }
        lead.value = response.payload
      })
    })

    watch(lead, (nv, ov) => {
      email.value = lead.value.email
      name.value = lead.value.name
      phone.value = lead.value.phone
      selectedModuleType.value = lead.value.gameCategory
      selectedModuleLength.value = lead.value.gameLength
    })

    const isValidEmail = (email) => {
      const bannedDomains = [
        '@163.com',
        '@aol.com',
        '@bellsouth.net',
        '@blueyonder.com',
        '@blueyonder.co.',
        '@btconnect.com',
        '@charter.com',
        '@comcast.net',
        '@cox.net',
        '@earthlink.net',
        '@email.com',
        '@gmail.co.',
        '@gmail.com',
        '@hotmail.co.',
        '@hotmail.com',
        '@juno.com',
        '@mail.com',
        '@mail.ru',
        '@mindspring.com',
        '@msn.com',
        '@ntlworld.com',
        '@orange.fr',
        '@rogers.com',
        '@sbcglobal.net',
        '@shaw.ca',
        '@sympatico.ca',
        '@telus.net',
        '@verizon.net',
        '@virgin.net',
        '@virginmedia.com',
        '@yahoo.ca',
        '@yahoo.com',
        '@yahoo.co.',
        '@ymail.com',
        '@icloud.com',
        '@me.com',
        '@mac.com'
      ]

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(email)) {
        console.log('Invalid email format')
        dialogMessageDangerState.value = true
        dialogMessage.value = 'Email address is invalid'
        return false // Invalid email format
      }

      for (let i = 0; i < bannedDomains.length; i++) {
        if (email.indexOf(bannedDomains[i]) !== -1) {
          dialogMessageDangerState.value = true
          dialogMessage.value = 'Please use a business email address'
          return false // Email contains a banned domain
        }
      }

      return true // Email is valid and doesn't contain a banned domain
    }

    const validatePhoneNumber = (phoneNumber) => {
      // Regular expression pattern for validating phone numbers
      const phonePattern = /^[+]?[0-9]{1,3}[-\s.]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}$/

      // Remove any non-digit characters from the phone number
      const strippedNumber = phoneNumber.replace(/\D/g, '')

      // Check if the stripped number matches the phone pattern
      return phonePattern.test(strippedNumber)
    }

    const detectMobile = () => {
      const { isMobile } = useMobileDetection()
      store.commit(Mutations.SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE, isMobile())
    }

    onMounted(() => {
      detectMobile()
    })

    return {
      submitButton,
      isLoginFading,
      formStep,
      styleObj,
      selectedModuleType,
      selectedModuleLength,
      email,
      name,
      phone,
      dialogMessage,
      onNextClicked,
      onPreviousClicked,
      onModuleTypeClicked,
      onModuleLengthClicked,
      onTestAGameClicked,
      dialogMessageDangerState,
      mobileViewMode
    }
  }
})
